<script>
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs } from 'vue'

const stepName = 'ReasonForLifeInsurance'

const keys = [
  'Protect a debt (ie. mortgage or medical bills)',
  'Cover end of life expenses such as funeral costs',
  'As income replacement',
  'Leave money for my family',
  'Leave money to an organization/charity',
  'Other'
]

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    const state = reactive({
      protect_debt: false,
      cover_end_of_life_expenses: false,
      income_replacement: false,
      leave_money_for_family: false,
      leave_money_for_charity: false,
      other: false
    })

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const handler = (id) => {
      state[id] = !state[id]
    }

    const setAnswer = () => {
      const fields = [
        {field: 'protected_debt', value: state.protect_debt ? 1 : 0},
        {field: 'cover_end_of_life_expenses', value: state.cover_end_of_life_expenses ? 1 : 0},
        {field: 'income_replacement', value: state.income_replacement ? 1 : 0},
        {field: 'leave_money_for_family', value: state.leave_money_for_family ? 1 : 0},
        {field: 'leave_money_for_charity', value: state.leave_money_for_charity ? 1 : 0},
        {field: 'other', value: state.other ? 1 : 0},
      ]

      store.commit('SET_FIELDS', fields)
      emit('next-step', stepName)
    }

    return {
      handler,
      ...toRefs(state),
      keys,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <div class="row checkbox-container-row">
      <div class="box">
        <div class="checkbox-container">
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="protect_debt">
            <label class="checkbox-label" @click="handler('protect_debt')">{{keys[0]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="cover_end_of_life_expenses">
            <label class="checkbox-label" @click="handler('cover_end_of_life_expenses')">{{keys[1]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="income_replacement">
            <label class="checkbox-label" @click="handler('income_replacement')">{{keys[2]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="leave_money_for_family">
            <label class="checkbox-label" @click="handler('leave_money_for_family')">{{keys[3]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="leave_money_for_charity">
            <label class="checkbox-label" @click="handler('leave_money_for_charity')">{{keys[4]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="other">
            <label class="checkbox-label" @click="handler('other')">{{keys[5]}}</label>
          </div>
        </div>

        <button
          class="main-button main-button-w mt-md"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/styles/component_styles/questionnaire/reason_for_life_insurance.scss';
</style>